<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Formas de pagamento</h3>
        </div>
        <div class="card-body">
          <table class="table table-head-bg-primary">
            <thead>
            <tr>
              <th scope="col">id</th>
              <th class="text-center" scope="col">nome</th>
              <th class="text-center" scope="col">descricao</th>
              <th class="text-center" scope="col">Parcelas</th>
              <th class="text-center" scope="col">acoes</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(lista, index) in lista_finan_forma_pagamentos"
                :key="index"
            >
              <td>{{ lista.id }}</td>
              <td class="text-center">
                {{ lista.nome }}
              </td>
              <td class="text-center">
                {{ lista.descricao }}
              </td>
              <td class="text-center">
                {{ lista.qtdparc }}
              </td>

              <td class="text-center">
                <button
                    v-show="lista_permissoes_filial.u_FinanFormaPagamento"
                    @click="atualizar(lista)"
                    class="btn btn-sm btn-icon btn-bg-primary btn-icon-light btn-hover-light"
                >
                  <i class="flaticon-edit-1"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.lock_FinanFormaPagamento"
                    @click="confirm(lista)"
                    class="ml-1 btn btn-sm btn-icon btn-bg-danger btn-icon-light btn-hover-light"
                >
                  <i class="flaticon-lock"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Formas de Pagamento"}]);
  },

  // created() {
  //   this.lista_matriculas();
  // },
  computed: {
    lista_finan_forma_pagamentos() {
      return this.$store.state.financeiro.lista_finan_forma_pagamentos;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_finan_forma_pagamento();
  },
  methods: {
    async listar_finan_forma_pagamento() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("financeiro/listar_finan_forma_pagamento").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },

    atualizar(value) {
      this.$router.push({name: "createFormaPagamento"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara essa forma de pagamento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("financeiro/bloquear_finan_forma_pagamento", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>